<template>
  <a-modal
    class="language-edit-modal"
    title="Редактировать язык"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="language">
      <div class="col-md-12">
        <div class="mb-4 text-center" v-if="iconUrl">
          <a-button @click="removeImage" type="link" danger><CloseCircleOutlined />Удалить фотографию</a-button>
        </div>
        <a-form-item label="Название языка">
          <a-input
            ref="languageName"
            placeholder="Введите название языка"
            v-model:value="language.name"
          />
        </a-form-item>
        <a-form-item label="Короткое название">
          <a-input
            ref="languageShort"
            placeholder="Введите короткое название"
            v-model:value="language.short"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import { CloseCircleOutlined } from '@ant-design/icons-vue';
import {ref, reactive, toRaw, onMounted, nextTick} from 'vue';
import { getBase64 } from "@/helpers/base64";
import { message } from "ant-design-vue";
import apiClient from "@/services/axios";
import Language from "@/models/Language";

const props = defineProps({
  languageId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let iconUrl = ref(''),
  loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  languageName = ref("languageName"),
  initialLanguage = new Language({}),
  language = reactive(initialLanguage);

const
  uploadImage = info => {
    iconUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      language.icon = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        iconUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = () => {
    iconUrl.value = ''
    language.icon = ''

    confirmLoading.value = true;
    apiClient
      .put(`/language/update?id=${props.languageId}`, { icon: '' })
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(err => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  resetForm = () => {
    Object.assign(language, new Language({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(language)

    return apiClient.put(`language/update?id=${props.languageId}`, model).then(response => {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  };

apiClient.get(`/language/view?id=${props.languageId}`).then(response => {
  if (response) {
    iconUrl.value = response.data.data.icon
    let model = new Language(response.data.data)
    Object.assign(language, model)
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  loading.value = false
})

onMounted(() => nextTick(() => languageName.value?.focus()))
</script>

<style scoped>
@import "./edit.css";
</style>
