<template>
  <a-button class="editable-add-btn" @click="openModal" style="margin-bottom: 16px">Добавить</a-button>
  <a-modal
    class="language-create-modal"
    title="Добавить язык"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="language">
      <div class="col-md-12">
        <a-form-item label="Название языка">
          <a-input
            ref="languageName"
            placeholder="Введите название языка"
            v-model:value="language.name"
          />
        </a-form-item>
        <a-form-item label="Короткое название">
          <a-input
            ref="languageShort"
            placeholder="Введите кототкое название"
            v-model:value="language.short"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, nextTick} from 'vue';
import apiClient from "@/services/axios";
import Language from "@/models/Language";

const emit = defineEmits(['reload']);

let
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  initialLanguage = new Language({}),
  languageName = ref("languageName"),
  language = reactive(initialLanguage);

const
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => languageName.value?.focus());
  },
  resetForm = () => {
    Object.assign(language, new Language({}));
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(language);

    return apiClient.post('/language/create', model).then(function (response) {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      modalVisible.value = false;
      confirmLoading.value = false;
      emit('reload')
    })
  }
</script>

<style scoped>
@import "./create.css";
</style>
